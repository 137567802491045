@import "./styles";

html {
    --mdc-typography-font-family: "Courier New", monospace;
    font-size: 17px;

    @media (max-width: $lg) {
        --mdc-typography-headline1-font-size: 5.9983rem;
        --mdc-typography-headline2-font-size: 3.75rem;
        --mdc-typography-headline3-font-size: 2.9991rem;
        --mdc-typography-headline4-font-size: 2.0243rem;
        --mdc-typography-headline5-font-size: 1.4993rem;
        --mdc-typography-headline6-font-size: 1.25rem;
    }

    @media (max-width: $md) {
        --mdc-typography-headline1-font-size: 5.3556rem;
        --mdc-typography-headline2-font-size: 3.333rem;
        --mdc-typography-headline3-font-size: 2.7849rem;
        --mdc-typography-headline4-font-size: 2.0243rem;
        --mdc-typography-headline5-font-size: 1.4993rem;
        --mdc-typography-headline6-font-size: 1.25rem;
    }

    @media (max-width: $sm) {
        --mdc-typography-headline1-font-size: 4.7129rem;
        --mdc-typography-headline2-font-size: 2.9167rem;
        --mdc-typography-headline3-font-size: 2.5707rem;
        --mdc-typography-headline4-font-size: 1.8219rem;
        --mdc-typography-headline5-font-size: 1.3118rem;
        --mdc-typography-headline6-font-size: 1.25rem;
    }

    @media (max-width: $xs) {
        --mdc-typography-headline1-font-size: 3.5rem;
        --mdc-typography-headline2-font-size: 2.375rem;
        --mdc-typography-headline3-font-size: 2rem;
        --mdc-typography-headline4-font-size: 1.5635rem;
        --mdc-typography-headline5-font-size: 1.25rem;
        --mdc-typography-headline6-font-size: 1.125rem;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.invisible {
    position: absolute;
    top: -999999em;
    left: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.app-container {
    @include themify($themes) {
        color: themed("textPrimary");
        background-color: themed("primaryBackgroundDefault");
    }
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }

    @include themify($themes) {
        color: themed("primaryMain");
    }
}

::selection {
    @include themify($themes) {
        color: themed("primaryDark");
        background-color: themed("primaryContrastText");
    }
}
